import * as React from "react";
import { motion } from "framer-motion";

import aboutBg from "../images/about.svg";
import { NavContext } from "../components/nav";

import * as aboutStyles from "./__styles/about.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import classes from "../utils/classes";

const IndexPage = ({ data }) => {
  const navContext = React.useContext(NavContext);

  React.useEffect(() => {
    navContext.setNav([{ name: "About", to: "/about" }]);
  }, []);

  return (
    <>
      <Helmet>
        <title>Quentin Golsteyn | About</title>
      </Helmet>
      <div className={aboutStyles.header__illustration}>
        <motion.img
          style={{
            scale: 1,
            opacity: 0,
            willChange: "transform",
          }}
          variants={{
            enter: {
              opacity: 0.5,
              scale: 1.2,
            },
            exit: {
              scale: 1.9,
              transition: { duration: 0.2, ease: "easeIn" },
            },
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          src={aboutBg}
        />
      </div>
      <motion.div
        className={aboutStyles.portrait__container}
        initial={{ opacity: 0 }}
        variants={{
          enter: { opacity: 1, transition: { duration: 0.4 } },
          exit: { opacity: 0, transition: { duration: 0.2 } },
        }}
      >
        <div>
          <StaticImage
            src="../../content/image/quentin.jpg"
            alt="Hi! It's me!"
            backgroundColor="rgb(34, 61, 125, 0.2)"
          />
        </div>
      </motion.div>
      <motion.div
        className="section--narrow"
        initial={{ opacity: 0 }}
        variants={{
          enter: { opacity: 1, transition: { duration: 0.3, delay: 0.2 } },
          exit: { opacity: 0, transition: { duration: 0.2 } },
        }}
      >
        <h1 className="centered">Hello! 👋</h1>
        <p className="larger">
          <b>I'm Quentin!</b> I am a front-end developer based in Vancouver,
          Canada. Currently working at{" "}
          <a href="https://www.linkedin.com/company/amazon-web-services">AWS</a>{" "}
          on the Route 53 team. Previously worked at{" "}
          <a href="https://www.park-depot.com/">Parkdepot</a> and{" "}
          <a href="https://www.pathmate-technologies.com/en/index">
            Pathmate Technologies
          </a>
          .
        </p>
        <p>
          I am passionate about <b>modern web technologies</b> and{" "}
          <b>building accessible user interfaces on the web</b>. I am proud to
          have worked on the following projects:
        </p>
        <ul>
          <li>
            <a href="https://ojs.library.queensu.ca/index.php/PCEEA/article/view/14199">
              <b>Wellness in Engineering</b>
            </a>{" "}
            - understanding how students perceive their wellbeing in their first
            year of engineering studies at UBC.
          </li>
          <li>
            <Link to="/writing/corner-kick">
              <b>Corner Kick</b>
            </Link>{" "}
            - Building a web application to test and debug a team of autonomous
            soccer playing robots.
          </li>
          <li>
            <Link to="/writing/cds">
              <b>Corona Data Scraper</b>
            </Link>{" "}
            - Tracking the spread of COVID 19 around the world.
          </li>
          <li>
            <Link to="/writing/pharm-drone">
              <b>Pharm Drone</b>
            </Link>{" "}
            - Locating flowers in drone imagery using computer vision.
          </li>
          <li>
            <a href="https://www.epfl.ch/labs/chili/index-html/research/chili-frog/">
              <b>FROG</b>
            </a>{" "}
            - Fabricating Real-time Orchestration Graphs @ EPFL.
          </li>
        </ul>
        <p>
          When I'm not working, you will find me hiking or kayaking in a remote
          part of British Columbia ⛰️.
        </p>
        <p>
          I can be found on{" "}
          <a
            className={classes("button", aboutStyles.social__button)}
            href="https://github.com/qgolsteyn"
            rel="me"
          >
            Github
          </a>{" "}
          and{" "}
          <a
            className={classes("button", aboutStyles.social__button)}
            href="https://linkedin.com/in/qgolsteyn"
            rel="me"
          >
            LinkedIn
          </a>
          . You can also contact me by{" "}
          <a
            className={classes("button", aboutStyles.social__button)}
            href="mailto:hello@golsteyn.com"
          >
            email
          </a>{" "}
          if you want to say hi or ask a front-end related question! Feel to
          reach out!
        </p>
      </motion.div>
    </>
  );
};

export default IndexPage;
